/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Menu from "./menu"
import {css} from "@emotion/react";
import logoPath from "../images/bg_image.jpg";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Menu siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className={'bg-gray-200 font-thin text-sm'}
           css={css`
        background: url(${logoPath}) no-repeat;
        background-size: contain;
      `}>
        <main>{children}</main>
        <footer className={'max-w-screen-lg mx-auto'}>
            © Silo Cleaning Service 2020. All rights reserved.
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

