import PropTypes from "prop-types"
import React from "react"

const Menu = () => (
  <header className={'bg-gray-600 w-full static'}>
    <div className={'max-w-screen-lg mx-auto'}>
        <div className={'text-white'}>
            <ul class={'md:inline-flex items-center'}>
                <li className={'m-2'}>
                    <a className="first" href="/" target="_self">Home</a>
                </li>
                <li className={'m-2'}>
                    <a className="" href="/about-us/" target="_self">About Us</a>
                </li>
                <li className={'m-2'}>
                    <a className="" href="/our-services/" target="_self">Our Services</a>
                </li>
                <li className={'m-2'}>
                    <a className="" href="/health-and-safety/" target="_self">Health &amp; Safety</a>
                </li>
                <li className={'m-2'}>
                    <a className="nav-selected nav-path-selected" href="/contact-us/" target="_self">Contact Us</a>
                </li>
            </ul>
        </div>
    </div>
  </header>
)

Menu.propTypes = {
  siteTitle: PropTypes.string,
}

Menu.defaultProps = {
  siteTitle: ``,
}

export default Menu
